import Audience from "@app2/models/Audience";
import Segment from "@app2/models/Segment";
import axios from '@app2/api/axios'

import segment from "@app2/modules/Audience/Segment/segment.store";
import person from "@app2/modules/Audience/Person/person.store";
import imports from "@app2/modules/Audience/Import/import.store";
import exports from "@app2/modules/Audience/Export/export.store";
import bulk from "@app2/modules/Audience/Bulk/bulk.store";
import {parseAttributes, prepareAttributes} from "@utils/attributeHelper";

const namespaced = true;

const state = {
  audiences: [],
  audienceAnalytics: null,
  audienceActivity: null,
  audienceAttributes: null,
  audienceAttributesInitial: null,
  audienceHubs: null,
  isLoading: false,
  selectedRowData: null,
  lastPage: 1,
  page: 1,
  total: 0,
  queryParams: {
    sort: '-created_at',
    filter: '',
    showPersonOnly: true
  },
  activityCurrentPage: 1,
  attributesCurrentPage: 1,
  initQueryParams: JSON.stringify({
    sort: '-created_at',
    filter: '',
    showPersonOnly: true
  }),
  fullQueryParams: {},
  sectionVisibilityParams: false,
  isFromAudienceHome: false,
  hasFilter: '',
  hubs: [],
  selectAll: false,
  segmentTemplate: [
    {
      logic: 'and',
      title: null,
      type: 'audience',
      groups: [],
      hidden: false
    }
  ],
  manageAccessOpen: false,
  importColumns: [],
  audienceImport: {
    columns: [
      {
        supported_field: 'Email',
        name: 'Email',
        key: 'email',
        header: null
      },
      {
        supported_field: 'First Name',
        name: 'First Name',
        key: 'first_name',
        header: null
      },
      {
        supported_field: 'Last Name',
        name: 'Last Name',
        key: 'last_name',
        header: null
      },
      {
        supported_field: 'Phone Number',
        name: 'Phone Number',
        key: 'phone',
        header: null
      },
    ],
    notify: 0,
    file: {},
    tags: [],
    hubs: [],
    source: null,
    attach_discussion_categories: []
  },
  recentImports: [],
  exitDialogSubtitle: '',
  selectedAudience: [],
  currentSortedColumn: null
};

const mutations = {
  SET_AUDIENCES(state, payload) {
    state.audiences = payload
  },
  SET_AUDIENCE_ANALYTICS(state, payload) {
    state.audienceAnalytics = payload
  },
  SET_AUDIENCE_ACTIVITY(state, payload) {
    state.audienceActivity = payload
  },
  SET_AUDIENCE_ATTRIBUTES(state, payload) {
    state.audienceAttributes = payload
  },
  RESET_AUDIENCE_ATTRIBUTES(state, remove = false) {
    if ( !state.audienceAttributes ) return;
    if (remove) {
      state.audienceAttributes = null;
      state.audienceAttributesInitial = null;
    } else {
      state.audienceAttributes.data = JSON.parse(state.audienceAttributesInitial)
    }
  },
  SET_AUDIENCE_ATTRIBUTES_INITIAL(state, payload) {
    state.audienceAttributesInitial = JSON.stringify(payload)
  },
  SET_NEW_AUDIENCE(state, payload) {
    state.total = state.total + 1
    state.audiences.unshift(payload)
  },
  REMOVE_AUDIENCE(state, payload) {
    state.audiences = state.audiences.filter(a => {
      if(!payload.find(p => p == a.id)) {
        return a
      }
    })
  },
  REMOVE_PERSON(state, payload) {
    state.total = state.total - 1
    state.audiences = state.audiences.filter(a => {
      if(a.id !== payload.id) return a
    })
  },
  SET_FROM_HOME(state, payload) {
    state.isFromAudienceHome = payload
  },
  BULK_UPDATE_AUDIENCE(state, payload) {
    let hubs = payload.hubs.filter(h => h.hasAccess)
    state.audiences = state.audiences.map(a => {
      if(payload.audiences.find(pa => pa.id == a.id)) {
        a.hubs = hubs
      }
      return a
    })
  },
  SET_HUBS(state, payload) {
    state.hubs = payload
  },
  UPDATE_LOADING_STATE(state, payload) {
    state.isLoading = payload
  },
  UPDATE_SELECTED_DATA(state, payload) {
    state.selectedRowData = payload
  },
  UNSET_AUDIENCES(state) {
    state.audiences = []
  },
  SET_SELECTED_ROW_DATA(state, payload) {
    state.selectedRowData = payload
  },
  UNSET_SELECTED_ROW_DATA(state, payload) {
    state.selectedRowData = null
  },
  SET_LAST_PAGE(state, payload) {
    state.lastPage = payload
  },
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_TOTAL(state, payload) {
    state.total = payload
  },
  UPDATE_TOTAL_COUNT(state) {
    state.total -= state.selectedAudience.length
  },
  RESET_QUERY_PARAMS(state) {
    state.queryParams = JSON.parse(state.initQueryParams);
  },
  SET_SORT_QUERY_PARAMS(state, payload) {
    state.queryParams.sort = payload
  },
  SET_SECTION_VISIBILITY_PARAMS(state, payload) {
    state.sectionVisibilityParams = payload
  },
  UNSET_SECTION_VISIBILITY_PARAMS(state) {
    state.sectionVisibilityParams = false
  },
  SET_FILTER_QUERY_PARAMS(state, payload) {
    state.queryParams.filter = payload
  },
  SET_AUDIENCE_TYPE_QUERY_PARAMS(state, payload) {
    state.queryParams.showPersonOnly = payload
  },
  SET_FULL_QUERY_PARAMS(state, payload) {
    state.fullQueryParams = payload
  },
  SET_HAS_DATA(state, payload) {
    state.hasData = payload
  },
  UPDATE_AUDIENCE_TAG(state, payload) {
    let index = state.audiences.findIndex(a => a.id == payload.pivot.taggable_id)

    if(!state.audiences[index].tags.find(t => t.name == payload.name)) {
      state.audiences[index].tags.push({name: payload.name})
    }
  },
  SET_ACTIVITY_PAGE(state, payload) {
    state.activityCurrentPage = payload
  },
  SET_ATTRIBUTE_PAGE(state, payload) {
    state.attributesCurrentPage = payload
  },
  SET_SELECT_ALL(state, payload) {
    state.selectAll = payload
  },
  SET_MANAGE_ACCESS_STATE(state, bool) {
    state.manageAccessOpen = bool
  },
  SET_AUDIENCE_HUB_ACCESS(state, payload) {
    state.audienceHubs = payload
  },
  SET_IMPORT_COLUMNS(state, payload) {
    state.importColumns = []
    state.importColumns.push({key: 'default', header: 'Do not import'})
    payload.forEach((p, index) => {
      state.importColumns.push({header: p, key: index})
    })
  },
  SET_MAP_COLUMN(state, payload) {
    // set header to null if key is in default value
    if(payload.key === 'default') {
      payload.column = null
    }
    state.audienceImport.columns[payload.index].header = payload.column
  },
  RESET_AUDIENCE_IMPORT(state, payload) {
    state.audienceImport = JSON.parse(payload)
  },
  SET_RECENT_IMPORTS(state, payload) {
    state.recentImports = state.recentImports.concat(...payload)
  },
  RESET_RECENT_IMPORTS(state, payload) {
    state.recentImports = []
  },
  SET_IMPORT_DATA(state, payload) {
    state.audienceImport[payload.key] = payload.data
  },
  SET_EXIT_DIALOG_SUBTITLE(state, payload) {
    state.exitDialogSubtitle = payload
  },
  SET_SELECTED_AUDIENCE(state, payload) {
    state.selectedAudience = payload
  },
  SET_CURRENT_SORTED_COLUMN(state, payload) {
    state.currentSortedColumn = payload
  }
}

const actions = {
  async getAudiences({ commit, state, rootState }, payload) {
    let params = {}

    commit('UPDATE_LOADING_STATE', true)
    commit('UNSET_AUDIENCES')
    commit('UNSET_SELECTED_ROW_DATA')
    if (rootState.v2.audience.segment.previewSegment || rootState.v2.audience.segment.currentSegment) {
      let segmentHash = (rootState.v2.audience.segment.previewSegment) ? rootState.v2.audience.segment.previewSegment : rootState.v2.audience.segment.currentSegment;
      let segment = Segment.find(segmentHash);
      if ( segment && segment.groups.length && segmentHash !== 'create-00' )
        params[`filter[segments]`] = [segmentHash];
    }
    if(state.page) {
      params.page = state.page;
    }
    if(state.queryParams.sort) {
      params.sort = state.queryParams.sort;
    }
    if(state.queryParams.filter) {
      params[`filter[name_or_email]`] = state.queryParams.filter;
    }
    // if(state.queryParams.showPersonOnly) {
    //   // if ( !params.filter ) params.filter = {};
    //   params['filter[is_person]'] = state.queryParams.showPersonOnly
    // }
    params.include = ['tags', 'hubs', 'achievements'];
    if (this._vm.$cantView('hubs')) {
      delete params.include[0];
    }
    let fullParams = {
      ...params,
      controller: rootState.filter.controllerName,
      isPerson: state.queryParams.showPersonOnly
    }

    commit('SET_FULL_QUERY_PARAMS', fullParams)

    let {response: {data}} = await Audience.api().fetch({
      params,
      cancelToken: payload?.cancelToken
    });
    if ( data ) {
      commit('SET_HAS_DATA', data.from)
      commit('SET_AUDIENCES', data.data);
      // commit('SET_AUDIENCES', []);
      commit('UPDATE_LOADING_STATE', false);
      commit('SET_LAST_PAGE', data.last_page);
      commit('SET_PAGE', data.current_page);
      commit('SET_TOTAL', data.total);
    }
  },
  getAudienceAnalytics({commit}, hash) {
    axios.get(`/api/audience/${hash}/analytics/snapshot`).then(({data}) => {
      commit('SET_AUDIENCE_ANALYTICS', data)
    })
  },
  getAudienceActivity({commit, state}, hash) {
    axios.get(`/api/audience/${hash}/analytics/activity`, {params: {page: state.activityCurrentPage}}).then(({data}) => {
      commit('SET_AUDIENCE_ACTIVITY', data)
      commit('SET_ACTIVITY_PAGE', data.meta.current_page)
    })
  },
  getAudienceAttributes({commit, state}, hash) {
    axios.get(`/api/internal/v2/audience/${hash}/attributes?limit=99999`, {params: {page: state.attributesCurrentPage}}).then(({data}) => {
      data.data = parseAttributes(data.data);
      commit('SET_AUDIENCE_ATTRIBUTES', data)
      commit('SET_AUDIENCE_ATTRIBUTES_INITIAL', data.data)
      commit('SET_ATTRIBUTE_PAGE', data.meta.current_page)
    })
  },
  getAudienceHubAccess({commit}, hash, page = 1) {
    axios.get(`/api/audience/${hash}/hubs`, {params: {include: ['price', 'discussionCategories'], page}}).then(({data}) => {
      commit('SET_AUDIENCE_HUB_ACCESS', data)
    })
  },
  async getHubs({commit}) {
    const { data } = await axios.get('/api/hubs')
    commit('SET_HUBS', data.data)
  },
  setSelectedRowData({ commit }, payload) {
    commit('SET_SELECTED_ROW_DATA', payload)
  },
  setSortQueryParams({commit}, payload) {
    commit('SET_SORT_QUERY_PARAMS', payload)
  },
  setAudienceTypeQueryParams({commit}, payload) {
    commit('SET_AUDIENCE_TYPE_QUERY_PARAMS', payload)
  },
  updateTags({commit}, payload) {
    payload.forEach(p => {
      commit('UPDATE_AUDIENCE_TAG', p)
    })
  },
  sendWelcomeEmail({}, { audienceHash, hubHash }) {
    return axios.post(`/api/audience/${audienceHash}/hubs/${hubHash}/send-welcome-email`);
  },
  impersonateAudience({}, { audienceHash, hubHash }) {
    axios.post(`/api/audience/${audienceHash}/impersonate/${hubHash}`).then(({data}) => {
      window.open(`${data}`, '_blank');
    })
  }
}

const getters = {
  getAudiences: state => {
    return state.audiences.map(a => ({
      id: a.id,
      name: a.name == null ? '-' : a.name,
      email: a.email == null ? '-' : a.email,
      session: 22,
      hubs: a.hubs ? a.hubs : [],
      last_seen_at: a.last_seen_at,
      created_at: a.created_at,
      audience_type: a.email == null ? 'Visitor' : 'Person',
      phone: a.phone,
      hash: a.hash,
      meta: a.meta,
      tags: a.tags,
      achievements: a.achievements && Array.isArray(a.achievements) ? a.achievements.map(ac => ac.id) : [],
      photo_url: a.photo ? a.photo : `https://ui-avatars.com/api/?name=${a.name}&rounded=true&size=100&font-size=0.35&background=e6f7d1&color=000000&bold=true`
    }))
  },
  getAudience: state => hash => {
    return state.audiences.find(a => a.hash === hash)
  },
  audienceAnalytics: state => state.audienceAnalytics,
  audienceActivity: state => state.audienceActivity,
  audienceAttributes: state => state.audienceAttributes,
  audienceAttributesInitial: state => state.audienceAttributesInitial,
  audienceHubs: state => state.audienceHubs,
  getHubs: state => state.hubs,
  isLoading: state => state.isLoading,
  selectedRowData: state => state.selectedRowData,
  lastPage: state => state.lastPage,
  page: state => state.page,
  total: state => state.total,
  queryParams: state => state.queryParams,
  hasFilter: state => state.queryParams.filter,
  isFromAudienceHome: state => state.isFromAudienceHome,
  fullQueryParams: state => state.fullQueryParams,
  sectionVisibilityParams: state => state.sectionVisibilityParams,
  activityCurrentPage: state => state.activityCurrentPage,
  attributesCurrentPage: state => state.attributesCurrentPage,
  segmentTemplate: state => state.segmentTemplate,
  isAllSelected: state => state.selectAll,
  manageAccessOpen: state => state.manageAccessOpen,
  importColumns: state => state.importColumns,
  columnHeaders: state => state.audienceImport.columns,
  csvFile: state => state.audienceImport.file,
  importTags: state => state.audienceImport.tags,
  importHubs: state => state.audienceImport.hubs,
  importNotify: state => state.audienceImport.notify,
  recentImports: state => state.recentImports,
  exitDialogSubtitle: state => state.exitDialogSubtitle,
  selectedAudience: state => state.selectedAudience,
  currentSortedColumn: state => state.currentSortedColumn,
}

const modules = {
  segment,
  person,
  imports,
  exports,
  bulk
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules
}
