import { allConditions, allConditionGroups, sortedConditionGroups } from '@app2/modules/Audience/Segment/segment.options';
import { set } from '@app2/utils/helpers';
import axios from '@app2/api/axios';
import Segment from '@app2/models/Segment';
import segmentRouter from '@app2/modules/Audience/Segment/segment.router';
import rootApp from '@app2/app';
import rootRouter from '@app2/router';
import AudienceAttribute from '@app2/models/AudienceAttribute';
import eventBus from '@app2/core/eventBus';
import get from 'lodash/get';
import { identify } from 'logrocket';
import { hasPermission } from '@app2/utils/filters';

const namespaced = true;

const state = {
  currentSegment: null,
  previewSegment: null,
  withParams: false,
  saving: false,
  applying: false,
  isModalOpen: false,
  selectModel: null,
  showFilters: false,
  segmentKey: 0,
  attributes: [],
};

const mutations = {
  SET_SELECT_MODEL(state, payload) {
    state.selectModel = payload;
  },
  SET_SEGMENT(state, payload) {
    state.currentSegment = payload;
    state.withParams = false;
  },
  SET_SEGMENT_KEY(state) {
    state.segmentKey++;
  },
  SET_PREVIEW(state, payload) {
    state.previewSegment = payload;
  },
  SET_APPLYING(state, payload) {
    state.applying = payload;
  },
  SET_SAVING(state, payload) {
    state.saving = payload;
  },
  SET_MODAL_STATE(state, payload) {
    state.isModalOpen = payload;
  },
  SET_SHOW_FILTERS(state, payload) {
    state.showFilters = payload;
  },
  SET_WITH_PARAMS(state, payload) {
    state.withParams = payload;
  },
  SET_ATTRIBUTES(state, payload) {
    state.attributes = payload;
  },
};

const actions = {
  async validate() {
    const segment = await Segment.find(state.currentSegment);
    set(segment, 'show_errors', true);
    if (segment.hasEmptyGroups) {
      this._vm.$toasted.show('Remove empty groups before saving or previewing.', { type: 'error' });
    } else if (segment.hasEmptyConditions) {
      this._vm.$toasted.show('Remove conditions with no set value before saving or previewing.', { type: 'error' });
    } else if (segment.hasValidationErrors) {
      this._vm.$toasted.show('Check errors in conditions and try again.', { type: 'error' });
    }
  },
  async setPreview({ commit, state, dispatch }, preview) {
    return new Promise(async resolve => {
      const sync = !rootRouter.currentRoute.name.includes('hubEditor');
      let segmentHash = state.currentSegment ? state.currentSegment : 'create-00';
      const segment = Segment.find(segmentHash);
      if (segment.isInvalid) {
        dispatch('validate');
        commit('SET_SAVING', false);
        return;
      }
      set(segment, 'preview', preview);
      if (typeof segment.id === 'number') {
        const { data } = await axios.put(`/api/segments/${segment.hash}`, {
          ...segment,
          sync,
        });
        await Segment.insertOrUpdate({ data });
        commit('SET_PREVIEW', data.hash);
        if (!preview) commit('SET_SEGMENT', data.hash);
        setTimeout(() => {
          resolve(data.hash);
        }, 1000);
      } else {
        const { data } = await axios.post('/api/segments/', {
          ...segment,
          sync,
        });
        await Segment.insertOrUpdate({ data });
        commit('SET_PREVIEW', data.hash);
        if (!preview) commit('SET_SEGMENT', data.hash);
        setTimeout(() => {
          resolve(data.hash);
        }, 1000);
      }
    });
  },
  async search({ commit, state, dispatch }, preview) {
    commit('SET_SAVING', true);
    if (preview) await dispatch('setPreview', preview);
    commit('v2/audience/SET_PAGE', 1, { root: true });
    dispatch('v2/audience/getAudiences', null, { root: true });
    commit('SET_SAVING', false);
  },
  async save({ state, commit, dispatch }, hash) {
    const sync = !rootRouter.currentRoute.name.includes('hubEditor');
    commit('SET_SAVING', true);
    const segment = Segment.find(hash ? hash : state.currentSegment);
    if (segment.isInvalid) {
      dispatch('validate');
      commit('SET_SAVING', false);
      return;
    }
    try {
      set(segment, 'hidden', false);
      set(segment, 'preview', false);
      if (typeof segment.id === 'number') {
        const { data } = await axios.put(`/api/segments/${segment.hash}`, {
          ...segment.toSaveProperties,
          sync,
        });
        await Segment.insertOrUpdate({
          data: {
            ...data,
            audiences: [],
            audiences_count: 0,
          },
        });
        eventBus.$emit('clearSegmentAudienceCount');
      } else {
        const { data } = await axios.post('/api/segments/', {
          ...segment,
          sync,
        });
        await Segment.insertOrUpdate({ data });
        let params = Object.assign(segmentRouter.currentRoute.params, { hash: data.hash });
        await segmentRouter.push({ name: 'Item', params });
      }
      segment.setInitial();
      rootApp.$toasted.show(`Segment has been successfully ${typeof segment.id === 'number' ? 'updated' : 'created'}!`, { type: 'success' });
    } catch (e) {
      rootApp.$toasted.show('Could not save segment, something went wrong. :(', { type: 'error' });
    }
    commit('SET_SAVING', false);
    commit('SET_APPLYING', false);
  },
  async newSegment({ state, commit, dispatch }, params) {
    let createSegment = Segment.find('create-00');
    if (createSegment) return;
    // set groups and index
    let groups = [];
    if (params) {
      let index = 0;
      // templates for hubs and tags
      function hubTemplate(hub, id) {
        return {
          id: id,
          condition: 'hubs',
          operator: 'equals',
          value: hub,
          type: 'standard',
        };
      }
      function tagTemplate(tag, id) {
        return {
          id: id,
          condition: 'tags',
          operator: 'equals',
          value: tag,
          type: 'standard',
        };
      }
      if (params.tags) {
        groups.push({
          logic: params.requireAllTags ? 'and' : 'or',
          id: `create-00`,
          conditions: params.tags.map((tag, ind) => tagTemplate(tag, `create-0${ind}`)),
        });
      }
      if (params.hub) {
        groups.push({
          logic: params.requireAllTags ? 'and' : 'or',
          id: `create-00`,
          conditions: [hubTemplate(params.hub, `create-00`)],
        });
      }
      // template for achievements
      function achievementTemplate(achievement, id) {
        return {
          id: id,
          condition: 'achievemnents',
          operator: 'equals',
          value: achievement,
          type: 'standard',
        };
      }
      if (params.achievements) {
        groups.push({
          logic: params.requireAllAchievements ? 'and' : 'or',
          id: `create-00`,
          conditions: params.achievements.map((achievement, ind) => achievementTemplate(achievement, `create-0${ind}`)),
        });
      }

      commit('v2/audience/UNSET_SECTION_VISIBILITY_PARAMS', false, { root: true });
    }
    await Segment.insertOrUpdate({
      data: {
        id: `create-00`,
        hash: `create-00`,
        title: 'Untitled Segment',
        logic: 'and',
        groups,
        hidden: true,
      },
    });
    commit('SET_SEGMENT', `create-00`);
    commit('v2/audience/SET_FILTER_QUERY_PARAMS', null, { root: true });
    if (!params) await segmentRouter.push({ name: 'Create' }).catch(() => {});
  },
  async resetNewSegment({ state, commit, dispatch }, withoutSetter) {
    await Segment.insertOrUpdate({
      data: {
        id: `create-00`,
        hash: `create-00`,
        title: 'Untitled Segment',
        logic: 'and',
        groups: [
          {
            logic: 'and',
            id: 'create-00',
            conditions: [
              {
                id: 'create-00',
                condition: null,
                operator: null,
                value: null,
                type: null,
              },
            ],
          },
        ],
        hidden: true,
      },
    });
    commit('SET_SEGMENT_KEY');
    if (!withoutSetter) commit('SET_SEGMENT', `create-00`);
  },
  getAttributes({ commit, state }) {
    if (state.attributes.length) return;
    axios.get(`/api/internal/v2/data/attributes?limit=99999`, { params: { page: state.attributesCurrentPage } }).then(({ data }) => {
      AudienceAttribute.insertOrUpdate({ data: data.data });
      commit('SET_ATTRIBUTES', data.data);
    });
  },
};

const getters = {
  getSegment: state => state.currentSegment,
  getPreview: state => state.previewSegment,
  getShowFilters: state => state.showFilters,
  getOptions() {
    return allConditionGroups;
  },
  getSortedOptions() {
    let groups = [];
    for (let group of sortedConditionGroups) {
      let subgroup = [];
      for (let list of group) {
        // if ( !hasPermission('segmenting') && list.id === 'hubActivity' ) list.disabled = true;
        subgroup.push(list);
      }
      groups.push(subgroup);
    }
    return groups;
  },
  getOption: state => option => {
    return allConditions.find(c => c.condition === option);
  },
  getOptionGroup: state => (option, fromCondition) => {
    return fromCondition
      ? allConditionGroups.find(g => {
          if (g.single) return g.option.condition === option;
          return g.options.filter(op => op.condition === option).length;
        })
      : allConditionGroups.find(g => g.id === option);
  },
  getOptionSubGroup: state => (group, subGroup) => {
    return allConditionGroups.find(g => g.id === group).options.find(s => s.id === subGroup);
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
