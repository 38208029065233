<template>
  <b-modal
    class="person-slide-in"
    id="bulk-dialog"
    modal-class="modal-right"
    static
    ref="modal"
    hide-footer
    hide-header
    v-model="isModalOpen"
    @hide="beforeClose"
  >
    <div id="bulk-app"></div>
  </b-modal>
</template>

<script>
  import App from '@app2/modules/Audience/Bulk/bulk.app';
  import router from "@app2/modules/Audience/Bulk/bulk.router";
  import eventBus from '@app2/core/eventBus';

  export default {
    name: 'AudienceBulkAction',
    computed: {
      isModalOpen: {
        get() { return this.$store.state.v2.audience.bulk.isModalOpen },
        set(val) { return this.$store.commit('v2/audience/bulk/SET_MODAL_STATE', val) },
      }
    },
    data() {
      return {
        hasChanged: false
      }
    },
    mounted() {
      this.app = App();
      this.$nextTick(() => {
        if ( !router.currentRoute.name ) router.push({name: 'BulkIndex'});
        eventBus.$on('audience-bulk-has-changed', status => {
          this.hasChanged = status;
        })
      });
    },
    methods: {
      beforeClose(e) {
        if ( this.hasChanged ) {
          e.preventDefault();
          this.$bvModal.show('audience-bulk-dialog');
          return;
        }
      }
    }
  }
</script>
