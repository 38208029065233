var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app",
      class: {
        "auth-view": _vm.isAuth,
        "no-min-width": _vm.$route.name == "watch"
      },
      attrs: { id: "app" }
    },
    [
      _c("icons-sprite"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.switchingTeam && _vm.switchTeam
            ? _c("switching-teams", { attrs: { team: _vm.switchTeam } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !(_vm.switchingTeam && _vm.switchTeam)
        ? [
            _c("badge-sprite"),
            _vm._v(" "),
            _c("language-icons-sprite"),
            _vm._v(" "),
            false ? _c("temporary-mobile") : _vm._e(),
            _vm._v(" "),
            _vm.$route.name !== "onboarding" && !_vm.isAuth && !!_vm.trial
              ? _c("trial-top-bar")
              : _vm._e(),
            _vm._v(" "),
            !_vm.isAuth && _vm.$store.state.hasLoadedFirstRoute
              ? _c("global-sidenav", {
                  attrs: {
                    "has-unread-notifications": _vm.hasUnreadNotifications,
                    notifications: _vm.notifications,
                    "loading-notifications": _vm.loadingNotifications
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("wisdom-settings-slide-in"),
            _vm._v(" "),
            _c("import-dialog"),
            _vm._v(" "),
            _c(
              "main",
              {
                staticClass: "main",
                class: {
                  "with-topbar":
                    _vm.$route.name !== "onboarding" &&
                    !_vm.isAuth &&
                    !!_vm.trial
                }
              },
              [
                _vm.isAuth
                  ? _c(
                      "auth-layout",
                      { attrs: { completed: _vm.authCompleted } },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              name: "only-down",
                              duration: 200,
                              mode: "out-in"
                            }
                          },
                          [
                            _c("router-view", {
                              on: {
                                completed: function($event) {
                                  _vm.authCompleted = true
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : [
                      _c(
                        "transition",
                        {
                          attrs: { name: "fade", duration: 200, mode: "out-in" }
                        },
                        [_c("router-view")],
                        1
                      )
                    ],
                _vm._v(" "),
                !_vm.isAuth && _vm.$store.state.addMedia.uploading
                  ? _c("upload-list", {
                      attrs: {
                        list: _vm.$store.state.addMedia.files,
                        abort: function() {
                          return _vm.$store.dispatch("addMedia/abort")
                        }
                      }
                    })
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("transcript-editor"),
            _vm._v(" "),
            _c("achievements-slide-in"),
            _vm._v(" "),
            _c("widget-editor"),
            _vm._v(" "),
            _c("wisdom-editor"),
            _vm._v(" "),
            _c("manage-payment", { attrs: { url: _vm.paymentUrl } }),
            _vm._v(" "),
            _c("sitemap-selector"),
            _vm._v(" "),
            _c("apps-sidepanel"),
            _vm._v(" "),
            _c("spotlight-search"),
            _vm._v(" "),
            _c("person-dialog"),
            _vm._v(" "),
            _vm.showOverlay
              ? [
                  _c("div", { staticClass: "modal show" }),
                  _c("div", { staticClass: "modal-backdrop" })
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }