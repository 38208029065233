import hubSettingsRoutes from '@app2/modules/Hubs/Settings/settings.router';

export default [
  {
    path: '/hubs',
    name: 'hubs',
    component: () => import(/* webpackChunkName: "hubs-v2" */ '@app2/modules/Hubs/Listing/Pages/Index'),
  },
  {
    path: '/hubs/templates',
    name: 'hubTemplates',
    component: () => import(/* webpackChunkName: "hubs-v2" */ '@app2/modules/Hubs/Templates/Pages/Index'),
  },
  {
    path: '/hubs/:hash',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Index'),
    children: [
      {
        path: '',
        name: 'hub',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Dashboard/Pages/Index'),
      },
      {
        path: 'editor',
        name: 'hubEditor',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
        redirect: '/hubs/:hash/editor/pages/homepage'
      },
      {
        path: 'editor/pages/dashboard',
        redirect: '/hubs/:hash/editor/pages/homepage'
      },
      {
        path: 'editor/pages/homepage',
        name: 'hubEditorDashboard',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
      },
      {
        path: 'editor/pages/:page',
        name: 'hubEditorPage',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
      },
      {
        path: 'audience',
        name: 'hubAudience',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Audience/Pages/Index'),
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Settings/Pages/Index'),
        name: 'hubSettings',
        children: hubSettingsRoutes
      },
      {
        path: 'payment',
        component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Payment/Pages/Index'),
        name: 'hubPayment'
      },
    ]
  },
];
