import Vue from 'vue';
import get from "lodash/get";
import Hub from "@app2/models/Hub";
import Page from "@app2/models/Page";
import router from "@app2/router";
import store from "@app2/store";
import editorRouter from "@app2/modules/Hubs/Editor/editor.router";

import history from "@app2/modules/Hubs/Editor/store/modules/history";
import revisions from "@app2/modules/Hubs/Editor/store/modules/revisions";
import pages from "@app2/modules/Hubs/Editor/store/modules/pages";
import discussions from "@app2/modules/Hubs/Editor/store/modules/discussions";
import {set} from "@app2/utils/helpers";

const namespaced = true;

const state = {
  sidebarOpen: false,
  pageActions: null,
  hubActions: null,
  attributeActions: null,
  exitRoute: null,
  pageFetching: false,
  sectionAddIndex: null,
  sectionIsDuplicating: false,
  blockAddIndex: null,
  elementAddIndex: null,
  menuAddItem: null,
  tempSection: null,
  tempBlock: null,
  onboardingStep: 'profile',
  sidenavTab: 'Pages',
  attributes: [],
  attributesSort: '-created_at'
};

const mutations = {
  TOGGLE_SIDEPANEL(state, payload) {
    state.sidebarOpen = payload
  },
  SET_SIDENAV_TAB(state, payload) {
    state.sidenavTab = payload;
  },
  SET_PAGE_ACTIONS(state, payload) {
    state.pageActions = payload;
  },
  SET_HUB_ACTIONS(state, payload) {
    state.hubActions = payload;
  },
  SET_ATTRIBUTE_ACTIONS(state, payload) {
    state.attributeActions = payload;
  },
  SET_TEMP_SECTION(state, payload) {
    state.tempSection = payload;
  },
  SET_TEMP_BLOCK(state, payload) {
    state.tempBlock = payload;
  },
  SET_EXIT_ROUTE(state, payload) {
    state.exitRoute = payload;
  },
  SET_PAGE_FETCHING(state, payload) {
    state.pageFetching = payload;
  },
  SECTION_ADD_INDEX(state, payload) {
    state.sectionAddIndex = payload;
  },
  SECTION_IS_DUPLICATING(state, payload) {
    state.sectionIsDuplicating = payload;
  },
  BLOCK_ADD_INDEX(state, payload) {
    state.blockAddIndex = payload;
  },
  ELEMENT_ADD_INDEX(state, payload) {
    state.elementAddIndex = payload;
  },
  MENU_ADD_ITEM(state, payload) {
    state.menuAddItem = payload
  },
  SET_ADD_MENU_PROP(state, payload) {
    state.menuAddItem[payload.key] = payload.value
  },
  SET_ONBOARDING_STEP(state, payload) {
    state.onboardingStep = payload;
  },
  SET_ATTRIBUTES(state, payload) {
    state.attributes = payload
  },
  SET_ATTRIBUTES_SORT(state, payload) {
    state.attributesSort = payload
  }
};

const actions = {
  addBlock({ commit }, payload) {
    const templates = {
      row: JSON.stringify({
        label: 'Column',
        title: 'Column',
        description: '',
        type: 'column',
        settings: {},
        blocks: [],
      }),
      scroll: JSON.stringify({
        title: '',
        label: '',
        description: '',
        type: 'grid-playlist',
        settings: {}
      }),
      grid: JSON.stringify({
        title: '',
        label: '',
        description: '',
        type: 'grid-playlist',
        settings: {}
      }),
      carousel: JSON.stringify({
        title: '',
        label: '',
        description: '',
        type: 'carousel-playlist',
        settings: {}
      }),
    }
    let block = JSON.parse(templates[payload.section.type]);
    block.id = `create-${+new Date()}`;
    if ( payload.section.type === 'row' ) {
      if (payload.section.blocks) {
        set(payload.section, 'blocks', (payload.index !== null)
          ? [
            ...payload.section.blocks.slice(0, payload.index),
            block,
            ...payload.section.blocks.slice(payload.index),
          ]
          : payload.section.blocks.concat([block]))
      }
      else set(payload.section, 'blocks', [block])
      Vue.nextTick(() => {
        payload.section.blocks.forEach(block => {
          set(block, 'settings.size', 100 / payload.section.blocks.length);
        });
        editorRouter.push({name: 'PageBlock', params: {hash: payload.page.hash, section: payload.section.id, block: block.id}})
      })
    } else {
      store.commit('v2/hubs/editor/SET_TEMP_BLOCK', block)
      Vue.nextTick(() => {
        editorRouter.push({name: 'PageBlockSetType', params: {hash: payload.page.hash, section: payload.section.id}})
      })
    }
  }
};

const getters = {
  page: (state) => (id, hash) => {
    const hub = Hub.find(hash);
    if ( (router.currentRoute.name === 'hubEditorDashboard') ) return Page.query().where('is_homepage', true).where('hub_id', val => val === id || val === hash).first();
    if (['login', 'register', 'onboarding'].includes(router.currentRoute.params.page)) {
      let page = Page.query().where(p => p.type === router.currentRoute.params.page).first()
      if ( page ) return page;
    } else {
      let page = Page.query().find(router.currentRoute.params.page);
      if ( page ) return page;
    }
    return get(hub, 'theme.config.pages', []).find(p => [router.currentRoute.params.page, router.currentRoute.params.id].includes(p.id));
  },
  exitRoute: (state) => state.exitRoute,
  pageFetching: (state) => state.pageFetching,
  pageActions: (state) => state.pageActions,
  hubActions: (state) => state.hubActions,
  attributes: (state) => state.attributes,
  attributeActions: (state) => state.attributeActions,
  attributesSort: (state) => state.attributesSort,
  sectionAddIndex: (state) => state.sectionAddIndex,
  elementAddIndex: (state) => state.elementAddIndex,
  menuAddItem: (state) => state.menuAddItem,
  tempSection: (state) => state.tempSection,
  tempBlock: (state) => state.tempBlock,
  customPages: (state) => (id, hash) => {
    const hub = Hub.find((hash) ? hash : router.currentRoute.params.hash);

    let pages = Page.query().where('hub_id', val => val === hub.id || val === hub.hash).orderBy('title').all()

    pages = pages.filter(page => !['onboarding', 'login', 'register', 'discussions'].includes(page.type))

    if ( Vue.prototype.$cant('discussions') ) {
      pages = pages.filter(p => p.type !== 'discussions' &&  p.id !== 'discussions')
    }
    
    return pages;
  },
  themePages: (state, getters, rootState) => (id) => {
    const getRealPage = (data, hub, pages) => {
      let page = pages.find(p => p.type === data.id);
      if ( !page ) page = data;
      if ( data.id === 'onboarding' && !hub.isPrivate ) {
        page.disabled = false;
      }
      if ( data.id === 'login' && !hub.isPrivate ) {
        page.disabled = false;
      }
      if ( data.id === 'register' && !hub.hasRegistration ) {
        page.disabled = true;
      }
      return page;
    };
    if ( !id ) id = router.currentRoute.params.hash;
    const hub = Hub.find(id);
    let themePages = JSON.parse(JSON.stringify(get(hub, 'theme.config.pages', [])));
    let pages = Page.query().where('hub_id', val => val === hub.id || val === hub.hash).orderBy('title').all()
    themePages = themePages.map(page => getRealPage(page, hub, pages));
    if ( Vue.prototype.$cant('audience-directory') ) {
      themePages = themePages.filter(p => p.id !== 'members')
    }
    if ( Vue.prototype.$cant('discussions') ) {
      themePages = themePages.filter(p => p.type !== 'discussions' &&  p.id !== 'discussions')
    }
    themePages.forEach((page) => {
      if ( !page.type ) page.type = 'theme';
      if ( !hub.isPrivate && (['login', 'register', 'payment', 'onboarding', 'account', 'discussions', 'members'].includes(page.id) || ['login', 'register', 'payment', 'onboarding', 'account', 'discussions'].includes(page.type)) ) {
        page.disabled = true;
      }
      if (!hub.hasRegistration) {
        if ( page.id === 'register' ) page.disabled = true;
      }
      if (!hub.hasPayments) {
        if ( page.id === 'payment' ) page.disabled = true;
      }
      if ( !rootState.v2.hubs.defaultContent.file ) {
        if ( page.id === 'player' ) page.disabled = true;
      }
      if ( !rootState.v2.hubs.defaultContent.playlist ) {
        if ( page.id === 'playlist' ) page.disabled = true;
      }
    });
    themePages = themePages.filter(p => ![p.id, p.type].includes('dashboard'))
    return themePages;
  },
  themePage: (state, getters, rootState) => (hub, id) => {
    return getters['themePages'](hub).find(page => page.id === id);
  }
};

const modules = {
  history,
  revisions,
  pages,
  discussions
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules,
};
