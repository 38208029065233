import Vue from 'vue';
import Folder from '@app2/models/Folder';
import Playlist from '@app2/models/Playlist';
import moment from 'moment';
import get from 'lodash/get';
import Renderer from "@plugins/Renderer/Renderer/Renderer";
import {isJson} from "@plugins/Renderer/verifyJSON";
import templateString from "@app2/utils/templateString";

export const hex2rgb = (hex, opacity) => {
  let h=hex.replace('#', '');
  h =  h.match(new RegExp('(.{'+h.length/3+'})', 'g'));

  for(let i=0; i<h.length; i++)
    h[i] = parseInt(h[i].length==1? h[i]+h[i]:h[i], 16);

  if (typeof opacity != 'undefined')  h.push(opacity);

  return h.join(',')
};

export const buildApiUrl = (url, options) => {
  if (options) {
    url += '?';
    Object.entries(options).forEach(([key, value], index) => {
      if (index !== 0) url += '&';
      url += `${key}=${value}`;
    });
  }
  return url;
};

export const set = (target, property, value) => {
  let properties = target,
      parentProperties = target,
      parentKey = '';


  if ( property.split('.').length > 1 ) {
    let keys = property.split('.');
    keys.forEach((key, index) => {
      if ( index < property.split('.').length - 1 ) {
        if ( !properties ) {
          Vue.set( parentProperties, parentKey, {} );
          properties = parentProperties[parentKey];
        }
        if ( !properties.hasOwnProperty(key) ) {
          Vue.set( properties, key, {} );
        }
        parentProperties = properties;
        parentKey = key;
        properties = properties[key];
      } else {
        Vue.set( properties, key, value );
      }
    });
  } else {
    Vue.set( properties, property, value );
  }

  if (target.$update) {
    target.$update(target)
  }

  return target;
};

export const clearFileInput = (ctrl) => {
  try {
    ctrl.value = null;
  } catch(ex) { }
  if (ctrl.value) {
    ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
  }
};

export const promiseTimeout = (ms, promise) => {
  let id;
  const timeout = new Promise((resolve, reject) => {
    id = setTimeout(() => {
      reject('Timed out in ' + ms + 'ms.')
    }, ms)
  })

  return Promise.race([
    promise,
    timeout
  ]).then((result) => {
    clearTimeout(id);
    return result;
  })
}

export const getFileDuration = (file) => {
  return promiseTimeout(1000, new Promise(function(resolve, reject) {
    let vid = document.createElement('video');
    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = function() {
      resolve(this.duration);
    };
    // create url to use as the src of the video
    vid.src = URL.createObjectURL(file);
  })).catch(e => {
    console.error(e);
    return null;
  });
};

export const humanReadableDuration = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);
  if (!seconds)
    return '0s';
  return h < 1
    ? `${m === 0 ? '' : m+'m'}${s === 0 ? '' : ' '+s+'s'}`
    : `${h}h${m === 0 ? '' : ' '+m+'m'}`;
};

export const humanReadableDurationWithMs = (seconds, msDigits = 3) => {
  const date = new Date(null);
  date.setSeconds(seconds);
  let timeString = date.toISOString().slice(11, 19);
  const milliseconds = Math.floor((seconds % 1) * 1000);
  let millisecondsString = milliseconds.toString().padStart(3, '0');
  millisecondsString = millisecondsString.slice(0, msDigits); // Limit to specified number of digits
  let formattedTime = `${timeString}.${millisecondsString}`;
  
  // Remove hours if they are zero
  if (parseInt(timeString.slice(0, 2)) === 0) {
    formattedTime = formattedTime.slice(3);
  }

  return formattedTime;
}

export const mediaFilters = {
  sources: [
    {
      value: 'facebook',
      label: 'Facebook'
    },
    {
      value: 'vimeo',
      label: 'Vimeo'
    },
    {
      value: 'zoom',
      label: 'Zoom'
    },
    {
      value: 'local',
      label: 'Uploads'
    },
    {
      value: 'podcast',
      label: 'Podcasts'
    },
    {
      value: 'dropbox',
      label: 'Dropbox'
    },
    {
      value: 'googledrive',
      label: 'Google Drive'
    },
    {
      value: 'wistia',
      label: 'Wistia'
    },
  ],
  status: [
    {
      value: 'processed',
      label: 'Processed'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'failed',
      label: 'Failed'
    },
    {
      value: 'raw',
      label: 'Not processed'
    },
  ],
  duration: [
    {
      value: '<20',
      label: 'Short (< 20 minutes)'
    },
    {
      value: '>30',
      label: 'Long (> 30 minutes)'
    },
  ],
  date: [
    {
      value: 'day',
      label: 'Today'
    },
    {
      value: 'week',
      label: 'Last week'
    },
    {
      value: 'month',
      label: 'Last month'
    },
    {
      value: 'year',
      label: 'Last year'
    },
  ]
};

export const getActiveFilters = filters => {
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null || Array.isArray(filters[key]) && !filters[key].length) {
      delete filters[key];
    }
    // if (Array.isArray(filters[key])) {
    //   let arrayString = '';
    //   filters[key].forEach((opt, index) => {
    //     arrayString += opt;
    //     if (index !== filters[key].length - 1) {
    //       arrayString += ','
    //     }
    //   });
    //   filters[key] = arrayString;
    // }
  });
  return filters;
};
const getFilterLabel = (type, value) => {
  if ( type === 'folders' ) {
    const folder = Folder.query().where('hash', value).first();
    if (folder)
      return folder.title;
    Folder.api().get(value);
    return '...';
  } else if ( type === 'playlists' ) {
    const playlist = Playlist.query().where('hash', value).first();
    if (playlist)
      return playlist.title;
    Playlist.api().get(value);
    return '...';
  } else if (type === 'tags') {
    return value;
  } else {
    return mediaFilters[type] && mediaFilters[type].find(f => f.value === value).label;
  }
};
export const getActiveFilterObjects = filters => {
  let filterObjects = [];
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null || (Array.isArray(filters[key]) && !filters[key].length)) {
      delete filters[key];
    }
  });
  Object.entries(filters).forEach(([key, value]) => {
    value = Array.isArray(value) ? value : [value];
    value.forEach(val => {
      filterObjects.push({
        type: key,
        value: val,
        label: getFilterLabel(key, val)
      });
    });
  });
  return filterObjects;
};

export const addProfitWellScript = email => {
  if ( document.getElementById('profitwell-js') ) return;
  let newScript = document.createElement("script");
  newScript.setAttribute('id', 'profitwell-js');
  newScript.setAttribute('data-pw-auth', '35f86e53e2658996e84b10b7fac16fa2');

  let inlineScript = document.createTextNode(`(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
       a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
       s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
       })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
       profitwell('start', { 'user_email': '${email}' });`);
  newScript.appendChild(inlineScript);
  document.head.appendChild(newScript);
};

export const appCuesSetup = user => {
  if ( !window.Appcues ) return;
  let created_at = moment(user.created_at).unix(),
    plan = get(user, 'teams.[0].current_billing_plan', null),
    language = get(user, 'teams.[0].meta.settings.transcription_language.code', null);
  window.Appcues.identify(
    user.id, // unique, required
    {

      // recommended (optional) properties

      createdAt: created_at, // Unix timestamp of user signup date
      purchasedAd: null, // Unix timestamp of account purchase date (leave null if empty)
      planTier: plan, // Current user’s plan tier
      accountId: user.id, // Current user's account ID
      firstName: user.name, // current user's first name

      // additional suggestions

      email: user.email, // Current user's email
      language: language, // for multi-language applications
    }
  );
};
export const copyToClipboard = async str => {
  try {
    await navigator.clipboard.writeText(str);
  } catch (error) {
    console.error(error);
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};

export const driftSetup = user => {
  if ( !window.drift || !user || !user.id) return;
  let created_at = moment(user.created_at).unix(),
      plan = get(user, 'teams.[0].current_billing_plan', null),
      language = get(user, 'teams.[0].meta.settings.transcription_language.code', null);
  drift.identify(`${user.id}`, {
    email: user.email,
    plan: plan ?? '',
    language: language,
    name: user.name,
    created_at: created_at
  });
};

export const timeBetween = (target, from) => {
  const localTarget = moment.utc(target).local();
  const fromDate = moment(from || new Date());
  const diff = moment.duration(localTarget.diff(fromDate));
  return {
    isDone: diff <= 0,
    fromNow: localTarget.fromNow(true),
    days: diff.asDays(),
    hours: diff.asHours(),
    mins: diff.asMinutes(),
    secs: diff.asSeconds()
  }
}
export const getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getExcerpt = (str, maxLen, separator = ' ') => {
  if (!str) return '';
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const embedResponsively = (frame, responsive = true) => {
  return (responsive)
    ? `<style>.searchie-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .searchie-container iframe, .searchie-container object, .searchie-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='searchie-container'>${frame}</div>`
    : frame;
};


export const trimString = (string, length) => {
  if(string.length > length) {
    return `${string.substring(0, length)}...`
  } else {
    return string
  }
}

export const prettyBites = (num) => {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  let exponent;
  let unit;
  let neg = num < 0;
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
}


export const formatToUnits = (number, precision) => {
  const abbrev = ['', 'k', 'm', 'b', 't'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
  const suffix = abbrev[order];

  return number ? (number / Math.pow(10, order * 3)).toFixed(precision) + suffix : 0;
}

export const numberFormat = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const percentageFormat = (number) => {
  return `${number ? Math.round(number) !== number ? number.toFixed(2) : number : 0}%`
}

export const mediaQuery = (options, unit = 'px') => {
  let query = 'screen';
  if ( options.maxWidth ) query += ` and (max-width: ${options.maxWidth + unit})`;
  if ( options.minWidth ) query += ` and (min-width: ${options.minWidth + unit})`;
  if ( options.maxHeight ) query += ` and (max-height: ${options.maxHeight + unit})`;
  if ( options.minHeight ) query += ` and (min-height: ${options.minHeight + unit})`;
  return window.matchMedia(query).matches;
}

export const emailFormatter = (value) => {
  if (!value) return value;
  let parts = value.split('@');
  if (parts.length !== 2) return value;
  parts[1] = parts[1].toLowerCase();
  return parts.join('@');
}

function startsWithAny(string, array) {
  for (let i = 0; i < array.length; i++) {
    if (string.startsWith(array[i])) {
      return true;
    }
  }
  return false;
}

export const isValidUrl = (str) => {
  const protocols = ['http:', 'https:', 'mailto:', 'tel:'];
  try {
    if (startsWithAny(str, protocols)) return true;
    else return str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null;
  } catch (err) {
    console.log(err)
    return false;
  }
}

export const manualSort = (data, property) => {
  if ( !property ) property = 'position';
  const emojiRegex = /\p{Extended_Pictographic}/u

  let hasEmoji = []
  let sortedList

  let sortSymbol = ''
  if(property[0] === '-') {
    sortSymbol = property[0]
    property = property.substr(1);
  }

  hasEmoji = data.filter(d => emojiRegex.test(d[property]))
  data = data.filter(d => !emojiRegex.test(d[property]))

  // if drag and drop option is enabled, reject the sort logic below
  if(property === 'position') return data

  const localSort = (a,b) => {
    return a[property]?.localeCompare(b[property], undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }

  if(sortSymbol === '') {
    data.sort(localSort)
    if(hasEmoji) hasEmoji.sort(localSort)

    sortedList = [...hasEmoji, ...data]
  } else {
    data.sort(localSort).reverse()
    if(hasEmoji) hasEmoji.sort(localSort).reverse()

    sortedList = [...data, ...hasEmoji]
  }

  return sortedList
}

export const renderedString = (string, audience) => {
  const renderer = new Renderer({noNodes: true});
  if ( !isJson(string) ) return audience ? templateString(string, audience) : string;
  let json = (typeof string === 'string') ? JSON.parse(string) : string;
  let result = renderer.render(json);
  result = result.replace(/&amp;/g, '&')
             .replace(/&lt;/g, '<')
             .replace(/&gt;/g, '>')
             .replace(/&#039;/g, "'")
             .replace(/&quot;/g, '"');
  if (audience) return templateString(result, audience)
  return result
}

export const richTextJsonToString = (json) => {
  const renderer = new Renderer({noNodes: true});
  return renderer.render(json)
}

export const scrollParentToChild = (parent, child) => {
  // Scroll child inside view of the parent

  var parentRect = parent.getBoundingClientRect();
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };

  var childRect = child.getBoundingClientRect();
  var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

  if (!isViewable) {
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        parent.scrollTop += scrollTop;
    } else {
        parent.scrollTop += scrollBot;
    }
  }
}

export const getFileTitle = (file) => {
  return file.title?.length > 20 ? file.title : ''
}

export const groupByDate = (data) => {
  const items = []
  let formatDate = (date) => {
    let d = new Date(date.split('T')[0]);
    let dayOfMonth = d.getDate();
    let monthName = d.toLocaleString('default', { month: 'long' });
    let year = d.getFullYear();
    let dayAffix;
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      dayAffix = 'st';
    }
    else if (dayOfMonth === 2 || dayOfMonth === 22) {
      dayAffix = 'nd';
    }
    else if (dayOfMonth === 3 || dayOfMonth === 23) {
      dayAffix = 'rd';
    }
    else {
      dayAffix = 'th';
    }
    return `${monthName} ${dayOfMonth}${dayAffix}, ${year}`;
  }
  for (let i = 0; i < data.length; i++) {
    let itemObj = { date: formatDate(data[i].created_at), item: data[i] };
    items.push(itemObj);
  }

  const groupedItems = items.reduce((result, currentItem) => {
    const date = currentItem.date;
    const item = currentItem.item;

    if (!result[date]) {
      result[date] = {
        date: date,
        items: [],
      };
    }

    result[date].items.push(item);

    return result;
  }, {});

  const finalGroupedItems = Object.values(groupedItems);
  return finalGroupedItems;
}

export const formatDateRange = (start, end) => {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let startMonth = startDate.toLocaleString('default', { month: 'short' });
  let endMonth = endDate.toLocaleString('default', { month: 'short' });
  let startDay = startDate.getDate();
  let endDay = endDate.getDate();
  let startYear = startDate.getFullYear();
  let endYear = endDate.getFullYear();
  let startAfix = 'th';
  let endAfix = 'th';
  if (startDay === 1 || startDay === 21 || startDay === 31) {
      startAfix = 'st';
  } else if (startDay === 2 || startDay === 22) {
      startAfix = 'nd';
  } else if (startDay === 3 || startDay === 23) {
      startAfix = 'rd';
  }
  if (endDay === 1 || endDay === 21 || endDay === 31) {
      endAfix = 'st';
  } else if (endDay === 2 || endDay === 22) {
      endAfix = 'nd';
  } else if (endDay === 3 || endDay === 23) {
      endAfix = 'rd';
  }
  return `${startDay}${startAfix} ${startMonth} - ${endMonth} ${endDay}${endAfix} ${endYear}`;
}
/**
 * Deep Object Difference Comparison
 *
 * Compares two nested JavaScript objects and retrieves differences between them.
 * It traverses both objects and identifies changes, additions, and deletions.
 *
 * @param {Object} obj1 - The first object for comparison.
 * @param {Object} obj2 - The second object for comparison.
 * @returns {Object} - An object containing the differences between obj1 and obj2.
 */
export const deepObjectDiff = (obj1, obj2) => {
  const changes = {};

  /**
   * Recursively compares objects and populates the changes object.
   *
   * @param {Object} innerObj1 - The inner object from obj1.
   * @param {Object} innerObj2 - The inner object from obj2.
   * @param {string} path - The current path within the objects.
   */
  function compareObjects(innerObj1, innerObj2, path) {
    for (const key in innerObj2) {
      // Compare blocks array separately due to special conditions
      if (key === 'blocks' && (innerObj1[key].length !== innerObj2[key].length || !innerObj1[key].every((block, index) => block.id === innerObj2[key][index].id))) {
        changes[path + key] = innerObj2[key];
      }
      // If key exists in obj2 but not in obj1, it's an addition
      else if (innerObj1 && !(key in innerObj1) || !innerObj1) {
        changes[path + key] = innerObj2[key];
      }
      // Recursively compare inner objects
      else if (
        typeof innerObj1[key] === 'object' &&
        typeof innerObj2[key] === 'object' &&
        !Array.isArray(innerObj1[key]) &&
        !Array.isArray(innerObj2[key])
      ) {
        // Handle the case when obj1's inner object is absent but obj2's is present
        if (!innerObj1[key] && innerObj2[key]) {
          changes[path + key] = innerObj2[key];
        } else {
          compareObjects(innerObj1[key], innerObj2[key], path + key + '.');
        }
      }
      // Compare arrays element-wise
      else if (
        Array.isArray(innerObj1[key]) &&
        Array.isArray(innerObj2[key])
      ) {
        // Handle the case when both arrays are empty
        if (!innerObj1[key].length && !innerObj2[key].length) {

        } else if (
          innerObj1[key].length > 0 &&
          innerObj2[key].length > 0 &&
          typeof innerObj1[key][0] === 'object' &&
          typeof innerObj2[key][0] === 'object'
        ) {
          // Compare elements within the arrays
          for (let i = 0; i < innerObj2[key].length; i++) {
            compareObjects(innerObj1[key][i], innerObj2[key][i], path + key + '.' + i + '.');
          }
        } else if (
          // If arrays are not equal
          innerObj1[key] !== innerObj2[key]
        ) {
          changes[path + key] = innerObj2[key];
        }
      }
      // Compare other types of values
      else if (
        innerObj1[key] !== innerObj2[key]
      ) {
        changes[path + key] = innerObj2[key];
      }
    }
  }

  // Start the comparison
  compareObjects(obj1, obj2, '');

  return changes;
};


export const FileMimeType = {
  'audio/x-mpeg': 'mpega',
  'application/postscript': 'ps',
  'audio/x-aiff': 'aiff',
  'application/x-aim': 'aim',
  'image/x-jg': 'art',
  'video/x-ms-asf': 'asx',
  'audio/basic': 'ulw',
  'video/x-msvideo': 'avi',
  'video/x-rad-screenplay': 'avx',
  'application/x-bcpio': 'bcpio',
  'application/octet-stream': 'exe',
  'image/bmp': 'dib',
  'text/html': 'html',
  'application/x-cdf': 'cdf',
  'application/pkix-cert': 'cer',
  'application/java': 'class',
  'application/x-cpio': 'cpio',
  'application/x-csh': 'csh',
  'text/css': 'css',
  'application/msword': 'doc',
  'application/xml-dtd': 'dtd',
  'video/x-dv': 'dv',
  'application/x-dvi': 'dvi',
  'application/vnd.ms-fontobject': 'eot',
  'text/x-setext': 'etx',
  'image/gif': 'gif',
  'application/x-gtar': 'gtar',
  'application/x-gzip': 'gz',
  'application/x-hdf': 'hdf',
  'application/mac-binhex40': 'hqx',
  'text/x-component': 'htc',
  'image/ief': 'ief',
  'text/vnd.sun.j2me.app-descriptor': 'jad',
  'application/java-archive': 'jar',
  'text/x-java-source': 'java',
  'application/x-java-jnlp-file': 'jnlp',
  'image/jpeg': 'jpg',
  'application/javascript': 'js',
  'text/plain': 'txt',
  'application/json': 'json',
  'audio/midi': 'midi',
  'application/x-latex': 'latex',
  'audio/x-mpegurl': 'm3u',
  'image/x-macpaint': 'pnt',
  'text/troff': 'tr',
  'application/mathml+xml': 'mathml',
  'application/x-mif': 'mif',
  'video/quicktime': 'qt',
  'video/x-sgi-movie': 'movie',
  'audio/mpeg': 'mpa',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpg',
  'video/mpeg2': 'mpv2',
  'application/x-wais-source': 'src',
  'application/x-netcdf': 'nc',
  'application/oda': 'oda',
  'application/vnd.oasis.opendocument.database': 'odb',
  'application/vnd.oasis.opendocument.chart': 'odc',
  'application/vnd.oasis.opendocument.formula': 'odf',
  'application/vnd.oasis.opendocument.graphics': 'odg',
  'application/vnd.oasis.opendocument.image': 'odi',
  'application/vnd.oasis.opendocument.text-master': 'odm',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.oasis.opendocument.graphics-template': 'otg',
  'application/vnd.oasis.opendocument.text-web': 'oth',
  'application/vnd.oasis.opendocument.presentation-template': 'otp',
  'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
  'application/vnd.oasis.opendocument.text-template': 'ott',
  'application/ogg': 'ogx',
  'video/ogg': 'ogv',
  'audio/ogg': 'spx',
  'application/x-font-opentype': 'otf',
  'audio/flac': 'flac',
  'application/annodex': 'anx',
  'audio/annodex': 'axa',
  'video/annodex': 'axv',
  'application/xspf+xml': 'xspf',
  'image/x-portable-bitmap': 'pbm',
  'image/pict': 'pict',
  'application/pdf': 'pdf',
  'image/x-portable-graymap': 'pgm',
  'audio/x-scpls': 'pls',
  'image/png': 'png',
  'image/x-portable-anymap': 'pnm',
  'image/x-portable-pixmap': 'ppm',
  'application/vnd.ms-powerpoint': 'pps',
  'image/vnd.adobe.photoshop': 'psd',
  'image/x-quicktime': 'qtif',
  'image/x-cmu-raster': 'ras',
  'application/rdf+xml': 'rdf',
  'image/x-rgb': 'rgb',
  'application/vnd.rn-realmedia': 'rm',
  'application/rtf': 'rtf',
  'text/richtext': 'rtx',
  'application/font-sfnt': 'sfnt',
  'application/x-sh': 'sh',
  'application/x-shar': 'shar',
  'application/x-stuffit': 'sit',
  'application/x-sv4cpio': 'sv4cpio',
  'application/x-sv4crc': 'sv4crc',
  'image/svg+xml': 'svgz',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'application/x-tcl': 'tcl',
  'application/x-tex': 'tex',
  'application/x-texinfo': 'texinfo',
  'image/tiff': 'tiff',
  'text/tab-separated-values': 'tsv',
  'application/x-font-ttf': 'ttf',
  'application/x-ustar': 'ustar',
  'application/voicexml+xml': 'vxml',
  'image/x-xbitmap': 'xbm',
  'application/xhtml+xml': 'xhtml',
  'application/vnd.ms-excel': 'xls',
  'application/xml': 'xsl',
  'image/x-xpixmap': 'xpm',
  'application/xslt+xml': 'xslt',
  'application/vnd.mozilla.xul+xml': 'xul',
  'image/x-xwindowdump': 'xwd',
  'application/vnd.visio': 'vsd',
  'audio/x-wav': 'wav',
  'image/vnd.wap.wbmp': 'wbmp',
  'text/vnd.wap.wml': 'wml',
  'application/vnd.wap.wmlc': 'wmlc',
  'text/vnd.wap.wmlsc': 'wmls',
  'application/vnd.wap.wmlscriptc': 'wmlscriptc',
  'video/x-ms-wmv': 'wmv',
  'application/font-woff': 'woff',
  'application/font-woff2': 'woff2',
  'model/vrml': 'wrl',
  'application/wspolicy+xml': 'wspolicy',
  'application/x-compress': 'z',
  'application/zip': 'zip',
  'text/csv': 'csv'
};

// Focus contenteditable element

export const focusContentEditableElement = (element) => {
  if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
    var range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(element);
      textRange.collapse(false);
      textRange.select();
  }
}

export const isDomain = (domain) => {
  try {
    // Check if the domain appears to be a valid URL
    if (!/^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(\/.*)?$/.test(domain)) {
      return false; // Not a valid URL format
    }

    // If the domain doesn't have a protocol, prepend "http://"
    if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
      domain = 'http://' + domain;
    }

    // Attempt to create a URL object
    const url = new URL(domain);
    return url.hostname !== '';
  } catch (error) {
    return false;
  }
}