const scriptUrl = 'https://code.tidio.co/wrq512tg9hb6k4u92thfh52ddxqkrcfp.js';

const loadScript = async (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (error) => {
      reject(error);
    });
    document.body.appendChild(script);
  });
};

const setListener = () => {
  window.tidioChatApi.on('ready', () => {
    window.tidioChatApi.hide();
  });

  window.tidioChatApi.on('close', function () {
    window.tidioChatApi.hide();
  });

  window.tidioChatApi.on('messageFromOperator', function () {
    window.tidioChatApi.show();
    window.tidioChatApi.open();
  });
};

const loadTidio = async () => {
  await loadScript(scriptUrl);
  setListener();
};

const unloadTidio = () => {
  if (!window.tidioChatApi) return;
  window.tidioChatApi.hide();
  const tidioScript = document.querySelector('script[src*="tidio"]');
  if (tidioScript) {
    tidioScript.remove();
  }
  delete window.tidioChatApi;
};

export { loadTidio, unloadTidio };
