import multiguard from 'vue-router-multiguard';
import {affiliateGuard} from '@app2/router/guards';
import {subscribedGuard} from "@app2/router/guards/subscription";
import {onboardGuard} from '@app2/router/guards/onboarding';
import {mediaQuery} from "@app2/utils/helpers";
import dataRoutes from "@app2/modules/Data/data.routes";
import webhookRoutes from "@app2/modules/Webhooks/webhooks.routes";

export default [
  {
    path: '/settings',
    name: 'settings',
    component: require('@app2/modules/AccountSettings/Pages/Index').default,
    redirect: (mediaQuery({
      maxWidth: 991
    })) ? '/settings/menu' : '/settings/profile',
    props: true,
    children: [
      {
        path: 'profile',
        name: 'AccountProfile',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Profile'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          nav: 'Settings',
          hideAddMedia: true
        }
      },
      {
        path: 'team',
        name: 'AccountTeam',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Team'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          nav: 'Settings',
          hideAddMedia: true
        }
      },
      {
        path: 'security',
        name: 'AccountSecurity',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Security'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          nav: 'Settings',
          hideAddMedia: true
        }
      },
      {
        path: 'subscription/cancel',
        name: 'CancelSubscription',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Subscription/Cancel'),
        beforeEnter: onboardGuard,
      },
      {
        path: 'subscription',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Subscription/Index'),
        redirect: '/settings/subscription/plan',
        children: [
          {
            path: 'plan',
            name: 'AccountSubscription',
            component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Subscription/Plan'),
            beforeEnter: onboardGuard,
            meta: {
              checkAndRemoveQueryParams: true
            }
          },
          {
            path: 'addons',
            name: 'AccountAddons',
            component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Subscription/Addons'),
            beforeEnter: onboardGuard,
          },
          {
            path: 'subscription',
            beforeEnter: (to, from, next) => next('/settings/subscription/plan'),
          },
        ],
        props: true,
        meta: {
          nav: 'Billing',
          hideAddMedia: true,
          name: 'AccountSubscription',
          meta: {
            checkAndRemoveQueryParams: true
          }
        }
      },
      {
        path: 'invoice',
        name: 'AccountInvoices',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Invoices/Index'),
        beforeEnter: onboardGuard,
        props: true,
        meta: {
          nav: 'Billing',
          hideAddMedia: true,
        },
      },
      {
        path: 'transcriptions',
        name: 'AccountTranscripts',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Transcripts'),
        beforeEnter: multiguard([onboardGuard, subscribedGuard]),
        props: true,
        meta: {
          nav: 'Options',
          hideAddMedia: true
        }
      },
      {
        path: 'player',
        name: 'AccountPlayer',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/AccountSettings/Pages/Routes/Player/Index'),
        beforeEnter: multiguard([onboardGuard, subscribedGuard]),
        props: true,
        meta: {
          nav: 'Options',
          hideAddMedia: true
        }
      },
      ...dataRoutes,
      ...webhookRoutes,
    ]
  },
  {
    path: '/rewards',
    name: 'affiliates',
    component: require('@app2/modules/AccountSettings/Pages/Affiliates').default,
    beforeEnter: multiguard([affiliateGuard, subscribedGuard]),
    props: true,
    meta: {
      hideAddMedia: true,
    }
  },
];
