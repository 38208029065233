const namespaced = true;

const state = {
  selectedSpace: null,
}

const mutations = {
  SET_SELECTED_SPACE(state, space) {
    state.selectedSpace = space
  },
}

const getters = {
  space: () => state.selectedSpace,
}

export default {
  namespaced,
  state,
  mutations,
  getters
}
