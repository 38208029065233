<template>
  <router-link :to="link" custom v-slot="{ navigate }">
    <div
      :class="[{active : active, 'type-radio' : radio, 'is-locked': isLock, 'px-2':radioSmall, 'v-centered': vCentered}, customClass]"
      @click="go(navigate, $event)"
      class="side-panel-option"
      :style="optionStyle"
      :ref="`option-menu-settings-${optionIndex}`"
    >
      <transition name="fade" appear>
        <i class="line" v-if="lineIndicator && active"></i>
      </transition>
      <div class="slot-left" v-if="(iconLeft || radio || checkbox)">
        <svg class="icon-regular" width="24" viewBox="0 0 24 24" v-if="iconLeft"><use :xlink:href="`#icon-${iconLeft}`"></use></svg>
        <div class="radio" :class="{active : active}" v-if="radio"></div>
        <div class="checkbox" :class="{active : active}" v-if="checkbox">
          <b-form-checkbox v-model="computedActive" />
        </div>
      </div>
      <div class="slot-left mr-0" v-else-if="image">
        <figure class="ag-username">
          <div class="avatar"><img :src="image"></div>
        </figure>
      </div>
      <slot name="left" v-else />
      <div class="inner" :class="innerClass">
        <template v-if="label">
          <div class="fx-row-center">
            <h3 v-if="radioSmall" class="txt-body" :class="{'txt-primary' : active, 'mb-0' : !description}">{{ label }}</h3>
            <h3 v-else class="txt-heading-small" :class="{'txt-primary' : active, 'mb-0' : !description}">{{ label }}</h3>
            <h3 v-if="status" class="txt-heading-small txt-bold ml-1" :class="`status-${status}`">{{ status }}</h3>
          </div>
        </template>
        <slot name="custom-label"></slot>
        <p class="txt-body" :class="isLock ? 'txt-muted-2' : 'txt-muted'" v-if="description">{{ description }}</p>
        <slot name="custom-description"></slot>
        <slot></slot>
      </div>
      <div class="slot-right" v-if="iconRight && !customRight" :class="{'txt-primary' : active}">
        <svg v-if="hasFilters" width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="3.46986" cy="3.00014" rx="2.57143" ry="2.57143" fill="#06211E"/></svg>
        <svg width="24" viewBox="0 0 24 24" v-if="iconRight"><use :xlink:href="`#icon-${iconRight}`"></use></svg>
      </div>
      <div class="slot-right" v-if="customRight">
        <slot name="right"></slot>
      </div>
    </div>
    <slot name="custom-cta"></slot>
    <b-tooltip
      v-if="tooltip"
      :target="() => $refs[`option-menu-settings-${optionIndex}`]"
      triggers="hover"
      :custom-class="tooltipClass"
      placement="top"
    >
      <slot name="tooltip"></slot>
    </b-tooltip>
  </router-link>
</template>
<script>
  export default {
    name: 'SidePanelOption',
    props: {
      iconLeft: {},
      iconRight: {},
      label: {},
      description: {},
      link: {
        default: ''
      },
      radio: {
        type: Boolean,
        default: false
      },
      checkbox: {
        type: Boolean,
        default: false
      },
      radioSmall: {
        type: Boolean,
        default: false
      },
      active: {
        type: [Boolean, Number],
        default: false
      },
      lineIndicator: {
        type: Boolean,
        default: false
      },
      isLock: {
        type: Boolean,
        default: false
      },
      optionIndex: {
        type: [String, Number],
        default: 0
      },
      vCentered: {},
      optionStyle: {},
      customRight: Boolean,
      hasFilters: Boolean,
      tooltip: Boolean,
      tooltipClass: String,
      image: String,
      customClass: String,
      innerClass: String,
      status: String
    },
    computed: {
      computedActive: {
        get() {
          return this.active
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      go(navigate, event) {
        if(!this.isLock) {
          if (this.link) {
             navigate(event)
          }
          this.$emit('route-changed')
        }
        this.$emit('click')
        return
      }
    }
  }
</script>
