var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-control-checkbox" },
    [
      _c(
        "b-checkbox",
        {
          attrs: {
            "unchecked-value": _vm.uncheckedValue,
            disabled: _vm.disabled
          },
          on: {
            change: function($event) {
              return _vm.$emit("change", $event)
            }
          },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _vm.label || _vm.description
            ? _c("span", { staticClass: "form-control-checkbox-slot" }, [
                _vm.label
                  ? _c("span", { class: _vm.labelClass }, [
                      _vm._v(_vm._s(_vm.label))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.description
                  ? _c("span", { class: _vm.descriptionClass }, [
                      _c("br"),
                      _vm._v(_vm._s(_vm.description))
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("label")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }