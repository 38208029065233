<template>
  <audience-slide-in-template
    back-label="Bulk Actions"
    :back-route="{name: 'BulkIndex'}"
    :title="$route.params.title"
  >
    <selected-audience />
    <hr class="mt-0 mb-3">
    <div class="side-panel-widget p-4">
      <form-control-select
        v-model="bulkHubAccess"
        placeholder="Search Hubs..."
        option-icon="hub"
        track-by="hash"
        select-label="title"
        tag-icon="hub"
        custom-class="txt-muted"
        :fetching="fetching"
        :type="`Hub${bulkHubAccess.length > 1 ? 's' : ''}`"
        :label="($route.name === 'HubsAttach' ? 'Give Access' : 'Remove Access')"
        :selected-title="($route.name === 'HubsAttach' ? 'Give access to' : 'Remove access from')"
        :sub-item-type="`Secret Space${secretSpaces.attach_discussion_categories.length > 1 ? 's' : ''}`"
        :sub-item-selected="secretSpaces.attach_discussion_categories"
        :options="hubsList.filter(hub => !hub.hasAccess)"
        :close-on-select="false"
        :search-visible="true"
        :actions="true"
        :identifier="infiniteId"
        tag-block
        tag-basic
        tag-row
        multiple
        infinite
        hide-selected 
        :sub-item="$route.name === 'HubsAttach' ? 'discussion_categories' : ''"
        :blockKeys="['Delete']"
        @search-change="search"
        @infinite="infiniteHandler"
        @remove-item="removeAccess"
        @sub-item="handleSpacesAttachments"
        @sub-item-redirect="handleSubItemRedirect"
      >
        <template #empty>
          <span class="txt-body" v-if="!fetching && !hubsList.filter(hub => !hub.hasAccess).length">No hubs found</span>
          <span v-else></span>
        </template>
      </form-control-select>
      <template v-if="$route.name === 'HubsAttach'">
        <hr class="my-4">
        <b-alert :show="$cant('send-email')" fade variant="info" class="mb-3">
          <svg width="24" class="alert-icon icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-status-question-color"></use></svg>
          <div class="alert-content">
            <span class="txt-body">To enable sending email notifications, contact support.</span>
            <a href="#" class="alert-link" @click.prevent="openChat">
              <b>Contact support.</b>
            </a>
          </div>
        </b-alert>
        <form-control-checkbox
          v-model="notify"
          label="Welcome email notification"
          description="If turned on the user will receive a welcome email to access these hubs."
          :disabled="$cant('send-email')"
        />
      </template>
    </div>
    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" size="lg" :disabled="isSaving" @click="confirmAccess">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success"></use></svg>
        Confirm
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import moment from '@app2/utils/moment';
  import Hub from '@app2/models/Hub';
  import get from 'lodash/get';
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import FormControlSelect from "@app2/core/Components/Common/Forms/Select";
  import FormControlCheckbox from "@app2/core/Components/Forms/Checkbox";
  import SelectedAudience from '@app2/modules/Audience/Bulk/Components/SelectedAudience'
  import rootVue from "@app2/app";
  import eventBus from '@app2/core/eventBus'
  import {debounce, uniqBy} from "lodash";
  import spacesMixin from '@app2/modules/Audience/space.mixin'

  export default {
    name: 'AudienceBulkAccess',

    components: {
      FormControlCheckbox,
      FormControlSelect,
      AudienceSlideInTemplate,
      SelectedAudience
    },
    mixins: [spacesMixin],
    data() {
      return {
        page: 1,
        total: null,
        infiniteId: new Date(),
        hubsList: [],
        fetching: false,
        initial: true,
        bulkHubAccess: [],
        toDetachHubs: [],
        notify: false,
        isSaving: false,
        query: '',
        secretSpaces: {
          attach_discussion_categories: [],
          detach_discussion_categories: []
        }
      }
    },
    computed: {
      selectedAudience() {
        return this.$store.getters['v2/audience/selectedAudience']
      }
    },
    watch: {
      selectedAudience: {
        handler(val) {
          if (!val?.length) {
            this.bulkHubAccess = []
            return
          }
          const hubIds = val.reduce((acc, audience) => {
            const audienceHubs = audience?.hubs ?? [];
            return acc.concat(audienceHubs.map(hub => hub.id).filter(Boolean));
          }, []);

          if (hubIds.length) {
            this.bulkHubAccess = Hub.query().whereIdIn(hubIds).get()
          } else {
            this.bulkHubAccess = []
          }
        },
        deep: true,
        immediate: true
      },
      bulkHubAccess(val) {
        eventBus.$emit('audience-bulk-has-changed', !!val.length)
      }
    },
    destroyed() {
      eventBus.$off('reset-bulk-edit-settings');
      eventBus.$off('save-bulk-edit-settings');
    },
    mounted() {
      this.infiniteId++;
      this.$root.$on('bv::modal::hidden', (data) => {
        if(data.componentId === 'add-person-modal' || data.componentId === 'hub-access-modal') {
          this.$store.commit('v2/audience/SET_MANAGE_ACCESS_STATE', false)
        }
      }),
      eventBus.$on('reset-bulk-edit-settings', () => {
        this.bulkHubAccess = [];
        setTimeout(() => {
          rootVue.$bvModal.hide('bulk-dialog');
        }, 100)
      }),
      eventBus.$on('save-bulk-edit-settings', () => {
        eventBus.$emit('audience-bulk-has-changed', false);
        this.confirmAccess();
      })
    },
    methods: {
      openChat() {
        if (!window.voiceflow) return;
        window.voiceflow.chat.show();
        window.voiceflow.chat.open();
      },  
      moment,
      search(keyword) {
        this.debouncer(() => {
          this.query = keyword;
          this.$nextTick(() => {
            this.infiniteId++;
          })
        });
      },
      debouncer: debounce((cb, args) => cb(args), 500),
      addHasAccessProp(obj) {
        // this function adds a reactive data in the specified object
        if(!('hasAccess' in obj)) {
          this.$set(obj, 'hasAccess', false)
        }

        if(!('tooltip' in obj)) {
          this.$set(obj, 'tooltip', (!obj.auth) ? 'Hub must be private to set access' : false);
        }

        if(!('$isDisabled' in obj)) {
          this.$set(obj, '$isDisabled', (!obj.auth) ? true : false);
        }

        return obj
      },
      async infiniteHandler($state) {
        try {
          this.fetching = true;
          const { response: {data} } = await Hub.api().fetch({ params: {
            page: this.page,
            query: this.query,
            include: ['audiencesCount', 'discussionCategories'],
          }});
          if (data.data.length) {
            this.page += 1;
            $state.loaded();
            // let result = this.addHasAccessProp(data.data)
            await Hub.insertOrUpdate({ data: data.data });
            // this.hubsList = data.data.filter((hub) => hub.auth === true);
            data.data.map((hub)=>{
              if (hub.auth === true) {
                this.hubsList.push(hub)
              }
            })
          }
          if (!data.links.next || !data.data.length) {
            $state.complete();
          }
          this.total = get(data,'meta.total', null);
        } catch (e) {
          $state.error(e);
          throw e;
        } finally {
          this.fetching = false;
        }
      },
      setAccess(hash) {
        this.hubsList.find(h => h.hash === hash).hasAccess = true;
      },
      removeAccess(hub) {
        this.toDetachHubs.push(hub)
      },
      async confirmAccess() {
        const notify = this.notify
        try {
          this.isSaving = true
          let result = null

          let payload = {}

          if ( this.$route.name === 'HubsAttach' ) {
            payload.notify = notify;
            payload.audiences = this.selectedAudience.map(a => a.id)
            payload.hubs = this.bulkHubAccess.map(({id}) => id)
            payload = {...payload, ...this.secretSpaces}
          } else {
            payload.audiences = this.selectedAudience.map(a => a.id)
            payload.hubs = this.bulkHubAccess.map(({id}) => id)
          }

          result = ( this.$route.name === 'HubsAttach' )
            ? await axios.post(`/api/internal/v2/audience/bulk/hubs/attach`, payload)
            : await axios.delete(`/api/internal/v2/audience/bulk/hubs/detach`, { params: payload })

          this.$toasted.show('Hub access updated successfully!', { type: 'success' });
          eventBus.$emit('remove-hub-access', this.toDetachHubs)
          eventBus.$emit('audience-bulk-has-changed', false)
          this.toDetachHubs = []

          rootVue.$bvModal.hide('bulk-dialog')
        } catch(err) {
          this.$toasted.show('Error while saving changes', { type: 'error' });
        } finally {
          this.isSaving = false
        }
      },
      handleSpacesAttachments() {
        const allSpaces = this.getAllHubsSpacesAttachments(this.bulkHubAccess);

        this.secretSpaces.attach_discussion_categories = allSpaces.attach
        this.secretSpaces.detach_discussion_categories = allSpaces.detach
      },
      handleSubItemRedirect(event) {
        const hubBaseUrl = event.item.domain ? `https://${event.item.domain}/discussions/c/${event.subItem.slug}` : `${process.env.MIX_APP_URL}/hub/${event.item.hash}/discussions/c/${event.subItem.slug}`;
        window.open(hubBaseUrl, '_blank');
      }
    }
  }
</script>
