var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-route": { name: "ImportIndex" },
        "back-label": "Back to Import Audience",
        hidePanelTitle: ""
      }
    },
    [
      _c("h6", { staticClass: "txt-title-small" }, [
        _c("b", [_vm._v("Step 1")]),
        _vm._v(" of 3 "),
        _c("br"),
        _c("span", { staticClass: "txt-muted" }, [_vm._v("Bulk actions")])
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c("div", { staticClass: "tag-list mb-3" }, [
        _c("span", { staticClass: "tag-basic tag-variant-dark" }, [
          _c(
            "svg",
            { staticClass: "icon-sm mr-2", attrs: { viewBox: "0 0 24 24" } },
            [_c("use", { attrs: { "xlink:href": "#icon-page-single" } })]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.fileName))])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "txt-body txt-muted mb-3" }, [
        _vm._v("\n    Organise your contacts import by adding "),
        _c("br"),
        _vm._v("tags and selecting Segment.\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _c("form-control-select", {
            attrs: {
              placeholder: "Search Audience tags...",
              "tag-placeholder": "Add new tag",
              "option-icon": "tag",
              title: "Add Audience Tags",
              "track-by": "name",
              "select-label": "name",
              "tag-icon": "tag",
              multiple: "",
              infinite: "",
              taggable: "",
              options: _vm.tagOptions,
              type: "tag" + (_vm.tags.length > 1 ? "s" : ""),
              "close-on-select": false,
              "hide-selected": "",
              searchVisible: "",
              blockKeys: ["Delete"]
            },
            on: { tag: _vm.addTag, infinite: _vm.tagsInfiniteHandler },
            model: {
              value: _vm.tags,
              callback: function($$v) {
                _vm.tags = $$v
              },
              expression: "tags"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _c(
            "div",
            { staticClass: "fx-row-center" },
            [
              _c(
                "span",
                {
                  staticClass: "txt-heading-small",
                  class: { "txt-muted-2": _vm.$cantView("hubs") }
                },
                [_vm._v("Give Access")]
              ),
              _vm._v(" "),
              _vm.$cantView("hubs")
                ? _c("upgrade-button", { staticClass: "ml-auto" })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("form-control-select", {
            staticClass: "pb-1",
            attrs: {
              placeholder: "Search hubs...",
              "option-icon": "hub",
              "track-by": "hash",
              "select-label": "title",
              "tag-icon": "hub",
              "selected-title": "Access given to",
              "sub-item-selected": _vm.subItemSelected,
              "sub-item-type":
                "Secret Space" + (_vm.subItemSelected.length > 1 ? "s" : ""),
              options: _vm.hubOptions,
              type: "hub" + (_vm.hubs.length > 1 ? "s" : ""),
              "close-on-select": false,
              actions: true,
              disabled: _vm.$cantView("hubs"),
              fetching: _vm.fetching,
              identifier: _vm.hubIdentifier,
              "sub-item": "discussion_categories",
              "tag-block": "",
              "tag-basic": "",
              "tag-row": "",
              multiple: "",
              infinite: "",
              "hide-selected": "",
              searchVisible: "",
              blockKeys: ["Delete"]
            },
            on: {
              infinite: _vm.hubsInfiniteHandler,
              "search-change": _vm.resetHubId,
              "sub-item": _vm.handleSpacesAttachments,
              "sub-item-redirect": _vm.handleSubItemRedirect
            },
            model: {
              value: _vm.hubs,
              callback: function($$v) {
                _vm.hubs = $$v
              },
              expression: "hubs"
            }
          }),
          _vm._v(" "),
          _vm.hubs.length
            ? [
                _c("hr", { staticClass: "mt-3 mb-4" }),
                _vm._v(" "),
                _c(
                  "b-alert",
                  {
                    staticClass: "mb-3",
                    attrs: {
                      show: _vm.$cant("send-email"),
                      fade: "",
                      variant: "info"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "alert-icon icon-sm",
                        attrs: { width: "24", viewBox: "0 0 24 24" }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-status-question-color" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "alert-content" }, [
                      _c("span", { staticClass: "txt-body" }, [
                        _vm._v(
                          "To enable sending email notifications, contact support."
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "alert-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openChat($event)
                            }
                          }
                        },
                        [_c("b", [_vm._v("Contact support.")])]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("form-control-checkbox", {
                  attrs: {
                    disabled: _vm.$cant("send-email"),
                    value: 1,
                    "unchecked-value": 0,
                    label: "Welcome email notification",
                    description:
                      "If turned on the user will receive a welcome email to access these hubs."
                  },
                  model: {
                    value: _vm.sendEmail,
                    callback: function($$v) {
                      _vm.sendEmail = $$v
                    },
                    expression: "sendEmail"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                to: { name: "UploadStep2" }
              }
            },
            [
              _vm._v("\n      Next Step\n      "),
              _c(
                "svg",
                {
                  staticClass: "btn-right",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-arrow-right" } })]
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }