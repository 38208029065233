export default {
  methods: {
    getAllHubsSpacesAttachments(hubs) {
      const allDiscussionCategories = hubs.reduce((acc, hub) => {
        if (hub.discussion_categories) {
          return [...acc, ...hub.discussion_categories];
        }
        return acc;
      }, []);

      return {
        attach: allDiscussionCategories.filter(c => c.isAttached).map(c => c.id),
        detach: allDiscussionCategories.filter(c => !c.isAttached).map(c => c.id)
      }
    }
  }
}
