var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-label": "Bulk Actions",
        "back-route": { name: "BulkIndex" },
        title: _vm.$route.params.title
      }
    },
    [
      _c("selected-audience"),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget p-4" },
        [
          _c("form-control-select", {
            attrs: {
              placeholder: "Search Hubs...",
              "option-icon": "hub",
              "track-by": "hash",
              "select-label": "title",
              "tag-icon": "hub",
              "custom-class": "txt-muted",
              fetching: _vm.fetching,
              type: "Hub" + (_vm.bulkHubAccess.length > 1 ? "s" : ""),
              label:
                _vm.$route.name === "HubsAttach"
                  ? "Give Access"
                  : "Remove Access",
              "selected-title":
                _vm.$route.name === "HubsAttach"
                  ? "Give access to"
                  : "Remove access from",
              "sub-item-type":
                "Secret Space" +
                (_vm.secretSpaces.attach_discussion_categories.length > 1
                  ? "s"
                  : ""),
              "sub-item-selected":
                _vm.secretSpaces.attach_discussion_categories,
              options: _vm.hubsList.filter(function(hub) {
                return !hub.hasAccess
              }),
              "close-on-select": false,
              "search-visible": true,
              actions: true,
              identifier: _vm.infiniteId,
              "tag-block": "",
              "tag-basic": "",
              "tag-row": "",
              multiple: "",
              infinite: "",
              "hide-selected": "",
              "sub-item":
                _vm.$route.name === "HubsAttach" ? "discussion_categories" : "",
              blockKeys: ["Delete"]
            },
            on: {
              "search-change": _vm.search,
              infinite: _vm.infiniteHandler,
              "remove-item": _vm.removeAccess,
              "sub-item": _vm.handleSpacesAttachments,
              "sub-item-redirect": _vm.handleSubItemRedirect
            },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function() {
                  return [
                    !_vm.fetching &&
                    !_vm.hubsList.filter(function(hub) {
                      return !hub.hasAccess
                    }).length
                      ? _c("span", { staticClass: "txt-body" }, [
                          _vm._v("No hubs found")
                        ])
                      : _c("span")
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.bulkHubAccess,
              callback: function($$v) {
                _vm.bulkHubAccess = $$v
              },
              expression: "bulkHubAccess"
            }
          }),
          _vm._v(" "),
          _vm.$route.name === "HubsAttach"
            ? [
                _c("hr", { staticClass: "my-4" }),
                _vm._v(" "),
                _c(
                  "b-alert",
                  {
                    staticClass: "mb-3",
                    attrs: {
                      show: _vm.$cant("send-email"),
                      fade: "",
                      variant: "info"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "alert-icon icon-sm",
                        attrs: { width: "24", viewBox: "0 0 24 24" }
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-status-question-color" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "alert-content" }, [
                      _c("span", { staticClass: "txt-body" }, [
                        _vm._v(
                          "To enable sending email notifications, contact support."
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "alert-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openChat($event)
                            }
                          }
                        },
                        [_c("b", [_vm._v("Contact support.")])]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("form-control-checkbox", {
                  attrs: {
                    label: "Welcome email notification",
                    description:
                      "If turned on the user will receive a welcome email to access these hubs.",
                    disabled: _vm.$cant("send-email")
                  },
                  model: {
                    value: _vm.notify,
                    callback: function($$v) {
                      _vm.notify = $$v
                    },
                    expression: "notify"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                block: "",
                variant: "primary",
                size: "lg",
                disabled: _vm.isSaving
              },
              on: { click: _vm.confirmAccess }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-status-success" } })]
              ),
              _vm._v("\n      Confirm\n    ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }