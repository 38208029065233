/**
 * Voiceflow Integration Service
 * 
 * This service provides functions to load and unload the Voiceflow chat widget.
 * It follows a similar pattern to other third-party integrations like Tidio.
 */

const projectID = '67bf6fb129e0a38f0da247e0';
const runtimeUrl = 'https://general-runtime.voiceflow.com';
const versionID = 'production';

/**
 * Loads a JavaScript script asynchronously
 * 
 * @param {string} src - URL of the script to load
 * @returns {Promise} - Promise that resolves when the script is loaded
 */
const loadScript = async (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (error) => {
      reject(error);
    });
    document.body.appendChild(script);
  });
};

/**
 * Event handler for Voiceflow message events
 * @param {MessageEvent} event - The message event
 */
const handleVoiceflowMessage = (event) => {
  try {
    // Voiceflow messages come as JSON strings with a type property
    if (typeof event.data === 'string' && event.data.includes('voiceflow:')) {
      const data = JSON.parse(event.data);
      
      if (data.type === 'voiceflow:close') {
        // When the widget is closed, hide it completely
        if (window.voiceflow && window.voiceflow.chat) {
          window.voiceflow.chat.hide();
        }
      }
    }
  } catch (error) {
    console.error('Error handling Voiceflow message:', error);
  }
};

/**
 * Sets up event listeners for the Voiceflow widget
 */
const setListener = () => {
  if (!window.voiceflow || !window.voiceflow.chat) return;
  
  // Hide the widget after initialization
  window.voiceflow.chat.hide();
  
  // Listen for window message events from Voiceflow
  window.addEventListener('message', handleVoiceflowMessage, false);
};

/**
 * Initializes the Voiceflow chat widget with user data
 * 
 * @param {Object} userData - Optional user data to pass to Voiceflow
 * @param {string} userData.user_name - User's name
 * @param {string} userData.user_email - User's email
 */
const initializeVoiceflow = (userData = {}) => {
  if (!window.voiceflow || !window.voiceflow.chat) return;
  
  const config = {
    verify: { projectID },
    url: runtimeUrl,
    versionID,
    voice: {
      url: "https://runtime-api.voiceflow.com"
    }
  };
  
  // Add user data if provided
  if (userData.user_name || userData.user_email) {
    config.launch = {
      event: {
        type: "launch",
        payload: {
          user_name: userData.user_name,
          user_email: userData.user_email
        }
      }
    };
  }
  
  window.voiceflow.chat.load(config).then(() => {
    // Set up event listeners after the widget is loaded
    setListener();
  });
};

/**
 * Loads the Voiceflow chat widget
 * 
 * @param {Object} userData - Optional user data to pass to Voiceflow
 * @returns {Promise} - Promise that resolves when Voiceflow is loaded
 */
const loadVoiceflow = async (userData = {}) => {
  try {
    await loadScript("https://cdn.voiceflow.com/widget-next/bundle.mjs");
    initializeVoiceflow(userData);
    return true;
  } catch (error) {
    console.error('Failed to load Voiceflow:', error);
    return false;
  }
};

/**
 * Shows the Voiceflow chat widget
 */
const showVoiceflow = () => {
  if (!window.voiceflow || !window.voiceflow.chat) return;
  window.voiceflow.chat.show();
};

/**
 * Hides the Voiceflow chat widget
 */
const hideVoiceflow = () => {
  if (!window.voiceflow || !window.voiceflow.chat) return;
  window.voiceflow.chat.hide();
};

/**
 * Unloads the Voiceflow chat widget
 */
const unloadVoiceflow = () => {
  if (!window.voiceflow || !window.voiceflow.chat) return;
  
  // Remove window event listener
  window.removeEventListener('message', handleVoiceflowMessage, false);
  
  // Remove the script from DOM
  const voiceflowScript = document.querySelector('script[src*="voiceflow"]');
  if (voiceflowScript) {
    voiceflowScript.remove();
  }
  
  // Clean up global object
  delete window.voiceflow;
};

export { loadVoiceflow, unloadVoiceflow, showVoiceflow, hideVoiceflow };
